import React from 'react';
import _ from 'lodash'
import './App.css';
import html2pdf from 'html2pdf.js'
import localforage from 'localforage'

const REASONS = {
    m1: 'deplasarea între domiciliu și locul de muncă, atunci când activitatea profesională este esențială și nu poate fi organizată sub formă de lucru la distanță sau deplasarea în interes profesional care nu poate fi amânată.',
    m2: 'consult medical de specialitate care nu poate fi amânat;',
    m3: 'deplasare pentru cumpărături de primă necesitate la unități comerciale din zona de domiciliu;',
    m4: 'deplasare pentru asigurarea asistenței pentru persoane în vârstă, vulnerabile sau pentru însoțirea copiilor;',
    m5: 'deplasare scurtă, lângă domiciliu, pentru desfășurarea de activități fizice individuale, în aer liber, cu excluderea oricărei forme de activitate sportivă colectivă;',
    m6: 'deplasare scurtă, lângă domiciliu, legată de nevoile animalelor de companie',
    m7: 'deplasare pentru rezolvarea următoarei situații urgente',
}


function Input(props) {
    const { onChange, value, type, ...rest } = props
    if (type == 'textarea') {
        return (
            <textarea type="text"
                onChange={onChange}
                value={value}
                {...rest}
            ></textarea>
        )
    }
    return (
        <input type="text"
            onChange={onChange}
            value={value}
            {...rest}
        />
    )
}

function DummyP(props) {
    const className = `paragraph ${props.className || ''}`
    return (
        <p className={className}>{props.children}</p>
    )
}

class App extends React.Component {
    constructor(props) {
        super(props)


        this.state = {
            nume: '',
            prenume_tata: '',
            prenume_mama: '',
            cnp: '',
            ci: {
                serie: '',
                numar: ''
            },
            localitate: '',
            judet: '',
            adresa: {
                strada: '',
                numar: '',
                bloc: '',
                etaj: '',
                apartament: '',

            },
            currentAddress: {
                localitate: '',
                judet: '',
                strada: '',
                numar: '',
                bloc: '',
                etaj: '',
                apartament: '',
            },
            deplasare: {
                interval: '',
                from: '',
                to: '',
                reasons: [],
                date: '',
                extra: ''
            },
            signature: '',


            processing: false,
            savedAddresses: {},
        }
    }


    handleChange = (target, e) => {
        let state = this.state
        _.set(state, target, e.target.value)
        this.setState(state)
    }

    handleCheckboxChange = (target, e) => {
        let state = this.state
        if (state.deplasare.reasons.indexOf(target) != -1) {
            _.remove(state.deplasare.reasons, (i) => {
                return i == target
            })
        } else {
            state.deplasare.reasons.push(target)
        }
        this.setState(state)
    }

    handleSubmit = (e) => {
        const opt = {
            filename: 'declaratie pr propria raspundere.pdf',
            margin: 13,
            html2canvas: { scale: 2, useCORS: true, scrollX: -7, scrollY: 0 },
            jsPDF: { orientation: 'portrait' }
        }
        this.setState({ processing: true }, () => {
            var element = document.getElementById('pdf');
            requestAnimationFrame(() => {
                html2pdf().set(opt).from(element).save().then(e => {
                    this.setState({ processing: false })
                })
            })
        })
    }


    handleSave = async (e) => {
        const value = await localforage.getItem('savedAddresses');
        console.log('value', value)
        const key = `${this.state.nume}-${this.state.localitate}`
        if (!value) {
            await localforage.setItem('savedAddresses', {
                [key]: _.omit(this.state, ['savedAddresses'])
            });
        } else {
            await localforage.setItem('savedAddresses', {
                ...value,
                [key]: _.omit(this.state, ['savedAddresses'])
            });
        }
    }

    async componentDidMount() {
        const savedAddresses = await localforage.getItem('savedAddresses') || {}
        this.setState({
            savedAddresses
        })
    }

    handleSelectAddress = (e) => {
        const key = e.target.value
        console.log(key)

        this.setState({
            ...this.setState,
            ...this.state.savedAddresses[key]
        })
    }



    render() {
        const { processing, savedAddresses } = this.state
        console.log('savedAddresses', savedAddresses)
        return (
            <div>
                <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet"></link>
                <link href="/styles.css" rel="stylesheet"></link>


                <div className="wrapper">
                    <div className="align-center">
                        <h1>Declarație pe propria răspundere</h1>

                        <h5>Acest website generează formularul pe care trebuie să-l completeze românii pentru a putea ieși din casă conform <a target="_blank" href="https://www.mai.gov.ro/ordonanta-militara-nr-3-din-24-03-2020-privind-masuri-de-prevenire-a-raspandirii-covid-19/">Ordonanței Militare nr. 3 din 24.03.2020</a> privind măsuri de prevenire a răspândirii COVID-19</h5>

                        <div className="message danger">
                            <p>Acest site <strong>nu colecteaza</strong>  nici un fel de informatie despre dumneavoastra sau dispozitivul dumneavoastra.</p>
                            <p>Informatiile introduse <strong>nu parasesc dispozitivul dumneavoastra</strong>.</p>
                        </div>
                    </div>

                    <div className="sep"></div>

                    <select onChange={this.handleSelectAddress}>
                        <option>Adrese salvate local</option>
                        {
                            Object.keys(savedAddresses).map((key, index) => {
                                return <option value={key} key={`${key}-${index}`}>{key}</option>
                            })
                        }
                    </select>

                    <div id="pdf">
                        <div className={processing ? 'processing' : ''} >
                            <DummyP className="center">Declarație pe proprie răspundere,</DummyP>
                            <DummyP className="indent">Subsemnatul(a) <Input value={this.state.nume}
                                onChange={this.handleChange.bind(this, 'nume')} />, fiul/fiica lui <Input className="medium" value={this.state.prenume_tata}
                                    onChange={this.handleChange.bind(this, 'prenume_tata')} />și al </DummyP>
                            <DummyP><Input className="medium" value={this.state.prenume_mama}
                                onChange={this.handleChange.bind(this, 'prenume_mama')} />, domiciliat(ă) în <Input className="medium" value={this.state.localitate}
                                    onChange={this.handleChange.bind(this, 'localitate')} />, județul/sectorul <Input className="medium" alue={this.state.judet}
                                        onChange={this.handleChange.bind(this, 'judet')} />, strada, </DummyP>
                            <DummyP><Input value={this.state.adresa.strada}
                                onChange={this.handleChange.bind(this, 'adresa.strada')} />, număr<Input className="small" value={this.state.adresa.numar}
                                    onChange={this.handleChange.bind(this, 'adresa.numar')} />, bloc<Input className="small" value={this.state.adresa.bloc}
                                        onChange={this.handleChange.bind(this, 'adresa.bloc')} />, etaj<Input className="small" value={this.state.adresa.etaj}
                                            onChange={this.handleChange.bind(this, 'adresa.etaj')} />, apartament <Input className="small" value={this.state.adresa.apartament}
                                                onChange={this.handleChange.bind(this, 'adresa.apartament')} />, având CNP </DummyP>
                            <DummyP><Input value={this.state.cnp}
                                onChange={this.handleChange.bind(this, 'cnp')} />, BI/CI seria <Input value={this.state.ci.serie}
                                    onChange={this.handleChange.bind(this, 'ci.serie')} />, număr <Input value={this.state.ci.numar} onChange={this.handleChange.bind(this, 'ci.numar')} />, </DummyP>
                            <DummyP className="indent">Locuind în fapt<sup>1</sup> în localitatea <Input className="medium" value={this.state.currentAddress.localitate}
                                onChange={this.handleChange.bind(this, 'currentAddress.localitate')} />, județul/sectorul <Input className="medium" classnamevalue={this.state.currentAddress.judet}
                                    onChange={this.handleChange.bind(this, 'currentAddress.judet')} />, strada, </DummyP>
                            <DummyP><Input value={this.state.currentAddress.strada}
                                onChange={this.handleChange.bind(this, 'currentAddress.strada')} />, număr <Input className="small" value={this.state.currentAddress.numar}
                                    onChange={this.handleChange.bind(this, 'currentAddress.numar')} />, bloc <Input className="small" value={this.state.currentAddress.bloc}
                                        onChange={this.handleChange.bind(this, 'currentAddress.bloc')} />, etaj <Input className="small" value={this.state.currentAddress.etaj}
                                            onChange={this.handleChange.bind(this, 'currentAddress.etaj')} />, apartament <Input className="small" value={this.state.currentAddress.apartament}
                                                onChange={this.handleChange.bind(this, 'currentAddress.apartament')} /></DummyP>
                            <DummyP className="indent">
                                <input type="checkbox" />Cunoscând prevederile articolului 326, referitoare la falsul în declarații<sup>2</sup>, precum și ale art. </DummyP>
                            <DummyP>352 referitoare la zădărnicirea combaterii bolilor din Noul Cod Penal, declar pe proprie răspundere </DummyP>
                            <DummyP>faptul că mă deplasez în interes profesional/personal, între orele <Input value={this.state.deplasare.interval}
                                onChange={this.handleChange.bind(this, 'deplasare.interval')} />, de la </DummyP>
                            <DummyP><Input
                                className="full"
                                value={this.state.deplasare.from}
                                onChange={this.handleChange.bind(this, 'deplasare.from')} />,</DummyP>
                            <DummyP>până la <Input value={this.state.deplasare.to}
                                onChange={this.handleChange.bind(this, 'deplasare.to')} />,</DummyP>
                            <DummyP>pentru<sup>3</sup>:</DummyP>

                            {Object.keys(REASONS).map((key, index) => {
                                return (
                                    <DummyP key={`reason-${key}`}
                                    ><input type="checkbox"
                                        onChange={this.handleCheckboxChange.bind(this, key)}
                                        />{REASONS[key]}</DummyP>
                                )
                            })}
                            <DummyP><Input type="textarea" value={this.state.deplasare.extra}
                                onChange={this.handleChange.bind(this, 'deplasare.extra')} />,</DummyP>

                            <DummyP>Atât declar, susțin și semnez</DummyP>
                            <DummyP className="spaced"><span className="half center">Data <Input value={this.state.deplasare.date}
                                onChange={this.handleChange.bind(this, 'deplasare.date')} /> </span><span className="half center">Semnătura, <Input value={this.state.signature}
                                    onChange={this.handleChange.bind(this, 'signature')} /></span></DummyP>


                            <div className="footer">
                                <DummyP className="small"><sup>1</sup>Se declară situația în care persoana nu locuiește la domiciliul prevăzut în actul de identitate</DummyP>
                                <DummyP className="small"><sup>2</sup>Declararea necorespunzătoare a adevărului, făcută unei persoane dintre cele prevăzute în art. 175 sau unei unităţi în care aceasta îşi desfăşoară activitatea în vederea producerii unei consecinţe juridice, pentru sine sau pentru altul, atunci când, potrivit legii ori împrejurărilor, declaraţia făcută serveşte la producerea acelei consecinţe, se pedepseşte cu închisoare de la 3 luni la 2 ani sau cu amendă.</DummyP>
                                <DummyP className="small"><sup>3</sup>Se completează motivul/cauzele deplasării.</DummyP>
                            </div>
                        </div>
                    </div>

                    <div className=" bottom align-center">
                        <button className="button success" onClick={this.handleSubmit}>Genereaza declaratie</button>
                        <button className="button primary" onClick={this.handleSave}>Salveaza datele si genereaza declaratie</button>
                    </div>
                    <div className="sep"></div>
                    <div className="align-center">
                        <div className="message primary">
                            <p>Datele salvate pot fi folosite la precompletarea automata ulterioara formularului</p>
                            <p>Informatiile introduse <strong>nu parasesc dispozitivul dumneavoastra</strong>.</p>
                        </div>
                    </div>
                    <div className="sep"></div>


                    <footer>
                        <div className="align-center">
                            {/* <p>Made by me Marioooo</p> */}
                        </div>
                    </footer>
                </div>




            </div >
        )
    }
}

export default App;
